import styled, {
  css,
} from 'styled-components';
import {
  controlsLabelStyle,
  ItemContainerBase,
} from './TwoColumnVizControlsGrid';

const gridLines = {
  top: 'threeColumnControlsTop',
  middle: 'threeColumnControlsMiddle',
  bottom: 'threeColumnControlsBottom',

  leftLabelLeft: 'threeColumnLeftLabelLeft',
  leftLabelRight: 'threeColumnLeftLabelRight',
  leftControlLeft: 'threeColumnLeftControlLeft',
  leftControlRight: 'threeColumnLeftControlRight',

  middleLabelLeft: 'threeColumnMiddleLabelLeft',
  middleLabelRight: 'threeColumnMiddleLabelRight',
  middleControlLeft: 'threeColumnMiddleControlLeft',
  middleControlRight: 'threeColumnMiddleControlRight',

  rightLabelLeft: 'threeColumnRightLabelLeft',
  rightLabelRight: 'threeColumnRightLabelRight',
  rightControlLeft: 'threeColumnRightControlLeft',
  rightControlRight: 'threeColumnRightControlRight',
};

export const LeftUppertLabelContainer = styled(ItemContainerBase)`
  ${controlsLabelStyle}
  grid-column: ${gridLines.leftLabelLeft} / ${gridLines.leftLabelRight};
  justify-content: flex-end;
  white-space: nowrap;
`;

export const LeftUpperControlContainer = styled(ItemContainerBase)`
  grid-column: ${gridLines.leftControlLeft} / ${gridLines.leftControlRight};
  justify-content: flex-start;
`;

export const MiddleUpperLabelContainer = styled(ItemContainerBase)`
  ${controlsLabelStyle}
  grid-column: ${gridLines.middleLabelLeft} / ${gridLines.middleLabelRight};
  justify-content: flex-end;
  white-space: nowrap;
`;

export const MiddleUpperControlContainer = styled(ItemContainerBase)`
  grid-column: ${gridLines.middleControlLeft} / ${gridLines.middleControlRight};
  justify-content: flex-start;
`;

export const RightUpperLabelContainer = styled(ItemContainerBase)`
  ${controlsLabelStyle}
  grid-column: ${gridLines.rightLabelLeft} / ${gridLines.rightLabelRight};
  justify-content: flex-end;
  white-space: nowrap;
`;

export const RightUpperControlContainer = styled(ItemContainerBase)`
  grid-column: ${gridLines.rightControlLeft} / ${gridLines.rightControlRight};
  justify-content: flex-start;
`;

const secondRowStyle = css`
  grid-row: 2;
`;
export const LeftLowerLabelContainer = styled(LeftUppertLabelContainer)`
  ${controlsLabelStyle}
  ${secondRowStyle}
`;
export const LeftLowerControlContainer = styled(LeftUpperControlContainer)`
  ${secondRowStyle}
`;
export const MiddleLowerLabelContainer = styled(MiddleUpperLabelContainer)`
  ${controlsLabelStyle}
  ${secondRowStyle}
`;
export const MiddleLowerControlContainer = styled(MiddleUpperControlContainer)`
  ${secondRowStyle}
`;
export const RightLowerLabelContainer = styled(RightUpperLabelContainer)`
  ${controlsLabelStyle}
  ${secondRowStyle}
`;
export const RightLowerControlContainer = styled(RightUpperControlContainer)`
  ${secondRowStyle}
`;
