import getYearlyDataCache, {
  IBaseState,
} from '../../sharedComponents/yearlyDataCache';
import {
  apiBaseURL,
  fetchJSON,
  ICYDatum,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export type IState = IBaseState<ICYDatum>;

type IHashInput = {};

interface IAPIResponse {
  data: ICYDatum[];
}

export const defaultHashFunction = () => '';

const FETCH_BEGIN = 'COUNTRY_YEAR_FETCH_BEGIN';
const FETCH_SUCCESS = 'COUNTRY_YEAR_FETCH_SUCCESS';
const FETCH_FAIL = 'COUNTRY_YEAR_FETCH_FAIL';
const FETCH_IF_NEEDED = 'COUNTRY_YEAR_FETCH_IF_NEEDED';

const {
  reducer,
  fetchDataEpic,
  fetchIfNeeded,
  getDataStatusSelector,
  getYearsStatusSelector,
} = getYearlyDataCache<
  IWorkerRootState,
  ICYDatum,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHashInput,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  outerHashFunction: defaultHashFunction,
  getCacheFromRootState: (rootState: IWorkerRootState) => rootState.countryYear,
  getFetchPromise: () => fetchJSON<IAPIResponse>(`${apiBaseURL}/data/location/?level=country`),
  getDataFromAPIResponse: ({data}: IAPIResponse) => data,
});

export default reducer;
export {fetchDataEpic, fetchIfNeeded, getDataStatusSelector, getYearsStatusSelector};
